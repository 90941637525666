/**
 * Default Cover
 * 
 * @Author: Focci
 * @Date: 2023-09-02 11:29:51
 * @Last Modified by: Focci
 * @Last Modified time: 2023-09-02 11:29:51
 */

import AspectRatioCover from '@comp/AspectRatioCover'
import SVGPicture from '@img/picture.svg'
import SVGPlayCircle from '@img/play-circle.svg'
import SVGFloorPlans from '@img/floor-plans.svg'
import { imageSize } from '@lib/image/loader'
import { useTranslations } from 'next-intl'
import PropTypes from 'prop-types'
import CoverScoff from './CoverScoff'

function CoverMask({ icon, label }) {
  return (
    <div className="flex items-center justify-center bg-black/40 text-white absolute z-1 inset-0">
      <div className="flex flex-col items-center gap-y-1">
        {icon}
        {label}
      </div>
    </div>
  )
}

function Cover({ alt, src, hoverZoomIn, transition, subtile = true }) {
  return (
    <AspectRatioCover
      subtile={subtile}
      unoptimized
      hoverZoomIn={hoverZoomIn}
      transition={transition}
      src={imageSize(src, 480, 360)}
      width={480}
      height={360}
      alt={alt || ''}
    />
  )
}

export function MultipleDefaultCoverScoff({
  children,
  variant,
  title,
  house = {},
  images = [],
  hoverZoomIn = false,
  transition = true,
}) {
  const th = useTranslations('house')

  return (
    <CoverScoff 
      variant={variant}
      secondChildren={
        <>
          {house.isVideo && (
            <CoverMask 
              icon={<SVGPlayCircle />} 
              label={th('videoPlaying')} 
            />
          )}
          <Cover
            src={house.videoImage || images[1]}
            alt={title}
            hoverZoomIn={hoverZoomIn}
            transition={transition}
          />
        </>
      }
      thirdChildren={
        <>
          {house.floorPlanImage && (
            <CoverMask 
              icon={<SVGFloorPlans />} 
              label={th('floorPlans')} 
            />
          )}
          <Cover
            src={house.floorPlanImage || images[2]}
            alt={title}
            hoverZoomIn={hoverZoomIn}
            transition={transition}
          />
        </>
      }
    >
      {children}
    </CoverScoff>
  )
}

export default function DefaultCover({
  variant,
  title,
  subtile,
  house = {},
  images = [],
  hoverZoomIn = false,
  transition = true,
}) {
  if(variant === 'normal') {
    return (
      <Cover
        subtile={subtile}
        src={images[0]} 
        alt={title} 
        hoverZoomIn={hoverZoomIn} 
        transition={transition} 
      />
    )
  }

  if(images.length < 3) {
    return (
      <AspectRatioCover
        subtile
        unoptimized
        hoverZoomIn={hoverZoomIn}
        transition={transition}
        src={imageSize(images[0], 864)}
        width={864.66}
        height={435}
        alt={title || ''}
      />
    )
  }

  return (
    <MultipleDefaultCoverScoff 
      variant={variant}
      title={title}
      house={house}
      images={images}
      hoverZoomIn={hoverZoomIn}
      transition={transition}
    >
      <div
        style={{ background: 'linear-gradient(transparent, rgba(0, 0, 0, 0.5))' }}
        className="
          flex items-center justify-end absolute z-1 left-0 right-0 bottom-0 
          text-white p-2 pointer-events-none
        "
      >
        <div className="flex items-center gap-x-1">
          <SVGPicture />
          {house?.images?.length}
        </div>
      </div>
      <Cover
        subtile={subtile}
        src={images[0]}
        alt={title}
        hoverZoomIn={hoverZoomIn}
        transition={transition}
      />
    </MultipleDefaultCoverScoff>
  )
}

DefaultCover.propTypes = {
  variant: PropTypes.string,
}
