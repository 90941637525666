/**
 * CoverWrapper
 * 
 * @Author: Focci
 * @Date: 2023-09-03 10:14:54
 * @Last Modified by: Focci
 * @Last Modified time: 2023-09-03 10:14:54
 */

import { propertyType } from '@lib/config'
import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'
import Chips from '../Chips'
import { PropertyStatusMaks } from '../StatusMask'
import HouseIcons from './HouseIcons'

const Favorite = dynamic(() => import('@page/common/favorite'), { ssr: false })
const TrackIconDialog = dynamic(() => import('@comp/page/property/estimate/track/TrackIconDialog'), { ssr: false })

export default function CoverWrapper({
  children,
  house,
  favoriteTrackingPageType,
  showFavorite,
  variant,
}) {
  return (
    <div className="relative overflow-hidden">
      {children}
      <div className="absolute left-2 top-2 z-2 pr-8">
        <Chips {...house} />
      </div>
      {showFavorite && !house.hideFavorite && (
        <div className="absolute right-3 top-3 z-2">
          <Favorite 
            houseId={house.id} 
            suburbId={house.suburbId} 
            house={house} 
            favoriteTrackingPageType={favoriteTrackingPageType} 
          />
        </div>
      )}
      {/* 查成交与估价, 显示认领图标 */}
      {[propertyType.SOLD, propertyType.ESTIMATE].indexOf(house.typeId) > -1 
        && house?.propertyId && (
        <div className="absolute right-3 top-3 z-2">
          <TrackIconDialog propertyId={house.propertyId} />
        </div>
      )}
      <PropertyStatusMaks status={house.status} />
      {/* 播放按钮 & 图片数量 & 户形图 */}
      {variant === 'normal' && (
        <HouseIcons house={house} />
      )}
    </div>
  )
}

CoverWrapper.propTypes = {
  children: PropTypes.node,
  house: PropTypes.object,
  showFavorite: PropTypes.bool,
  variant: PropTypes.string,
}
