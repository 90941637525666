/**
 * 房源卡片
 * 
 * @Author: Focci
 * @Date: 2023-09-01 17:34:16
 * @Last Modified by: Focci
 * @Last Modified time: 2023-09-01 17:34:16
 */

'use client'

import PropTypes from 'prop-types'
import { useLocale } from 'next-intl'
import { isArray } from 'lodash-es'
import { houseSearchType } from '@lib/config'
import { propertyDetail } from '@lib/route/house'
import PropertyCardHomePlan from './PropertyCardHomePlan'
import PropertyCardSingle from './PropertyCardSingle'
import PropertyCardDefault from './PropertyCardDefault'

export default function PropertyCard({
  type,
  variant = 'normal', // normal, multiple 展示多张图片
  house = {},
  agentAvatarProps,
  cardWrapperClassName,
  slideChangeTrackingPageType,
  favoriteTrackingPageType
}) {
  const locale = useLocale()
  const { showCase, isFeature, slug, simpleAgents, agentAvatar, agentName } = house || {}
  const coverProps = {
    variant, 
    type, 
    house, 
    isPromote: showCase || isFeature,
    url: propertyDetail(slug, locale),
    isHouse: [houseSearchType.sold, houseSearchType.estimate].indexOf(type) === -1, 
    slideChangeTrackingPageType, 
    favoriteTrackingPageType,
    agentAvatarProps,
    agents: isArray(simpleAgents) && simpleAgents.length > 1
      ? simpleAgents.slice(0, 2)
      : [{ avatar: agentAvatar, name: agentName }],
  }
  
  // 单列
  if(variant === 'multiple') {
    // 有子项目的房源
    if(isArray(house.homePlans) && house.homePlans.length > 0) {
      return (
        <PropertyCardHomePlan coverProps={coverProps} />
      )
    }

    return (
      <PropertyCardSingle 
        coverProps={coverProps}
        className={cardWrapperClassName}
      />
    )
  }

  return (
    <PropertyCardDefault
      coverProps={coverProps}
      className={cardWrapperClassName}
    />
  )
}

PropertyCard.propTypes = {
  type: PropTypes.string,
  variant: PropTypes.oneOf(['normal', 'multiple']),
  house: PropTypes.object,
  cardWrapperClassName: PropTypes.string,
  slideChangeTrackingPageType: PropTypes.string,
  favoriteTrackingPageType: PropTypes.string,
}
