/**
 * PropertySoldDateLabel
 * 
 * @Author: Focci
 * @Date: 2023-12-13 21:56:11
 * @Last Modified by: Focci
 * @Last Modified time: 2023-12-13 21:56:11
 */

import PropTypes from 'prop-types'
import { formatLang, isWithinMonth } from '@lib/datetime'
import { useLocale, useTranslations } from 'next-intl'

export default function PropertySoldDateLabel({
  soldData,
  Tag = 'span',
  ...props
}) {
  const locale = useLocale()
  const ts = useTranslations('search')

  if(!soldData || !isWithinMonth(soldData)) {
    return null
  }

  return (
    <Tag {...props}>
      {formatLang(soldData * 1000, ts('soldDate'), locale)}
    </Tag>
  )
}

PropertySoldDateLabel.propTypes = {
  soldData: PropTypes.number,
  Tag: PropTypes.string,
}
