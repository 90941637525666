/**
 * ShareAndFavorite
 * 
 * @Author: Focci
 * @Date: 2023-10-01 09:09:05
 * @Last Modified by: Focci
 * @Last Modified time: 2023-10-01 09:09:05
 */

'use client'

import PropTypes from 'prop-types'
import useApp from '@hook/useApp'
import dynamic from 'next/dynamic'
import useTracking from '@hook/useTracking'
import Favorite, { FavoriteCountBody } from '@page/common/favorite'
import { ShareButtonVertical } from '@comp/page/property/favorite-and-share/ShareButton'
import { useTranslations } from 'next-intl'
import { propertyDetail } from '@lib/route/house'
import { houseToGta, isAU } from '@lib/utils'

const Share = dynamic(() => import('@comp/share'), { 
  ssr: false,
  loading: () => <ShareButtonVertical />
})

function FavoriteTrigger({ houseId }) {
  const tc = useTranslations('common')
  const { favorites } = useApp()
  const actived = favorites.indexOf(houseId) > -1

  return (
    <FavoriteCountBody
      label={actived ? tc('collectioned') : tc('collection')}
      actived={favorites.indexOf(houseId) > -1}
    />
  )
}

export default function ShareAndFavorite({
  house = {}
}) {
  const { pushTrack, pushTrackShare } = useTracking()

  function handleBeforeShare() {
    pushTrackShare('click_share', house)
    isAU && pushTrack('homely_shared_listing', houseToGta(house))
  }

  return (
    <div className="px-2 flex items-center gap-x-4">
      <div>
        <Share 
          buttonClassName="rounded-full"
          trigger={<ShareButtonVertical />}
          shareData={{ url: propertyDetail(house.slug), text: house.title }}
          onBeforeShare={handleBeforeShare}
          onPanelClick={() => pushTrackShare('click_share_sent', house)}
        />
      </div>
      {!house.hideFavorite && (
        <div>
          <Favorite 
            houseId={house.id}
            suburbId={house.suburbId}
            house={house}
            Trigger={FavoriteTrigger}
            buttonProps={{ houseId: house.id }}
          />
        </div>
      )}
    </div>
  )
}

ShareAndFavorite.propTypes = {
  house: PropTypes.object,
}
