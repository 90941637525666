// import imgVR from '@img/vr.png'
import PropertyIcons from '@page/common/card/property/PropertyIcons'
// import Image from 'next/image'
import Link from 'next/link'
import clsx from 'clsx'
import { isValid } from '@lib/utils'
import { useTranslations } from 'next-intl'
import PropertyPriceLabel from './PropertyPriceLabel'
import PropertySoldDateLabel from './PropertySoldDateLabel'
import { PropertyTags } from './Chips'
import ShareAndFavorite from './ShareAndFavorite'

export function CardBriefSimple({
  house = {},
  url = '',
  variant,
}) {
  const isMultiple = variant === 'multiple'
  const {
    propertyIcon = {},
    address = '',
    soldData = null,
    typeId,
    type,
    priceLabel,
    priceDegree,
  } = house

  return (
    <Link className="flex flex-col gap-y-1 p-2 font-medium" href={url}>
      <div className="flex items-center justify-between">
        <PropertySoldDateLabel
          soldData={soldData}
          className="text-error font-medium text-f.6"
        />
        {isMultiple && <PropertyTags data={house.tags} />}
      </div>
      {isValid(priceLabel) && (
        <PropertyPriceLabel 
          typeId={typeId}
          type={type}
          priceLabel={priceLabel}
          priceDegree={priceDegree}
          className="text-base font-medium text-c3b"
        />
      )}
      <PropertyIcons
        iconWidth=".8rem" 
        data={propertyIcon} 
      />
      {address && (
        <div className="line-clamp-2">{address}</div>
      )}
      {!isMultiple && <PropertyTags data={house.tags} />}
    </Link>
  )
}

export default function CardBrief({
  url = '',
  house = {},
  variant,
}) {
  const ts = useTranslations('search')
  const isMultiple = variant === 'multiple'

  const {
    // vrIcon = false,
    propertyIcon = {},
    address = '',
    listAtLabel = '',
    listAtLabelColor = '',
    title = '',
    animation,
    typeId,
    type,
    priceLabel,
    priceDegree,
  } = house

  return (
    <div className="p-2 flex flex-col gap-1 flex-auto">
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          {animation && (
            <span className="inline-block border px-0.5 text-meta text-f.6">
              {ts('promote')}
            </span>
          )}
          <span 
            className="text-f.6 text-error font-medium"
            style={{ color: listAtLabelColor }}
          >
            {listAtLabel || ''}
          </span>
        </div>
        {isMultiple && <PropertyTags data={house.tags} />}
        {/* <div className="flex items-center gap-2">
          {vrIcon && (
            <Image
              priority 
              src={imgVR.src || ''}
              width={60}
              height={19}
              alt="VR"
            />
          )}
        </div> */}
      </div>
      <div 
        className={clsx([
          'flex items-center gap-x-3 md:gap-x-6', 
          isMultiple && 'flex-col md:flex-row flex-auto'
        ])}
      >
        <Link href={url} className="block flex-auto flex flex-col gap-y-1">
          <PropertyPriceLabel 
            typeId={typeId}
            type={type}
            priceLabel={priceLabel}
            priceDegree={priceDegree}
            className="text-base font-medium text-c3b"
          />
          {isMultiple && (
            <div className="line-clamp-2 text-f.9 text-info">
              {title}
            </div>
          )}
          <PropertyIcons
            iconWidth=".8rem"
            className="gap-2 font-medium" 
            data={propertyIcon} 
          />
          <div className="line-clamp-1 text-f.8 font-medium">
            {address}
          </div>
          {!isMultiple && <PropertyTags data={house.tags} />}
        </Link>
        {isMultiple && <ShareAndFavorite house={house} />}
      </div>
    </div>
  )
}
