/**
 * 房源卡片 Skeleton
 * 
 * @Author: Focci
 * @Date: 2023-06-12 13:38:10
 * @Last Modified by: Focci
 * @Last Modified time: 2023-06-12 13:38:10
 */

// import PropTypes from 'prop-types'
import { Skeleton } from '@comp/material'
import clsx from 'clsx'

export function VerticalCardSkeleton({
  coverClassName = 'aspect-[4/3]'
}) {
  return (
    <div className="rounded-md shadow-listing overflow-hidden">
      <div className={clsx(['group relative bg-cf0 relative', coverClassName])} />
      <div className="group bg-white relative">
        {/* Agent */}
        <div className="py-1 px-2">
          <div className="flex items-center w-full">
            <div className="w-14">
              <Skeleton variant="circular" width="2.5rem" height="2.5rem" />
            </div>
            <div className="ml-2 flex-auto">
              <Skeleton variant="text" width="70%" />
              <Skeleton variant="text" width="3.5rem" />
            </div>
          </div>
        </div>
        {/* Brief */}
        <div className="p-2">
          <Skeleton variant="text" width="70%" />
          <Skeleton variant="text" width="3.5rem" />
          <Skeleton variant="text" width="50%" />
        </div>
      </div>
    </div>
  )
}
