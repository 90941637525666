/**
 * 房源动画展示卡片
 * 
 * @Author: Focci
 * @Date: 2023-07-31 13:58:40
 * @Last Modified by: Focci
 * @Last Modified time: 2023-07-31 13:58:40
 */

'use client'

import PropTypes from 'prop-types'
import clsx from 'clsx'
import PropertyIcons from '@page/common/card/property/PropertyIcons'
import AspectRatioCover from '@comp/AspectRatioCover'
import { imageSize } from '@lib/image/loader'
import { useEffect, useRef, useState } from 'react'
import './index.css'

export default function AnimatedViewer({
  images = [],
  propertyIcon = {},
  title = '',
  priceLabel = '',
  agentAvatar = '',
  officeName = '',
  agentName = '',
  agentEmail = '',
  agentPhone = '',
  className = 'aspect-[600/450] bg-cf0',
  onPlayFinish = () => {}
}) {
  const [play, setPlay] = useState(false)
  const imgs = images.slice(0, 2).map((url) => imageSize(url, 480))
  const agentBody = useRef()

  useEffect(() => {
    const timer = setTimeout(() => setPlay(true), 1000) // 延迟1s播放
    return () => clearTimeout(timer)
  })

  useEffect(() => {
    const el = agentBody.current
    el.addEventListener('animationend', onPlayFinish, false)
    return () => {
      el.removeEventListener('animationend', onPlayFinish, false)
    }
  }, [onPlayFinish])
  
  return (
    <div className={clsx(['relative', className])}>
      <div
        className={clsx([
          'absolute inset-0',
          play && 'animated-viewer-active'
        ])} 
      >
        {
          imgs.map((img, i) => (
            <div
              key={img}
              className={clsx([
                'animated-viewer-section', 
                `animated-viewer-section-${i}`
              ])}
            >
              <AspectRatioCover
                priority
                src={img}
                wrapperClassName="absolute inset-0"
                width={400}
                height={400}
              />
            </div>
          ))
        }
        <div className="animated-viewer-intro">
          <div className="w-full">
            <h4 className="line-clamp-3">{title}</h4>
            <PropertyIcons
              iconWidth=".8rem" 
              data={propertyIcon} 
            />
            <h5>{priceLabel}</h5>
          </div>
        </div>
        <div className="animated-viewer-agent">
          <div 
            className="animated-viewer-avatar animated-viewer-avatar-effect2" 
            ref={agentBody}
            style={{ backgroundImage: `url(${imageSize(agentAvatar, 96)})` }}
          />
          <div className="animated-viewer-agent-body">
            {officeName && <p>{officeName}</p>}
            {agentName && <h5>{agentName}</h5>}
            {agentEmail && <p>{`E: ${agentEmail}`}</p>}
            {agentPhone && <p>{`P: ${agentPhone}`}</p>}
          </div>
        </div>
      </div>
    </div>
  )
}

AnimatedViewer.propTypes = {
  images: PropTypes.array,
  propertyIcon: PropTypes.object,
  title: PropTypes.string,
  priceLabel: PropTypes.string,
  agentAvatar: PropTypes.string,
  officeName: PropTypes.string,
  onPlayFinish: PropTypes.func,
}
