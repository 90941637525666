/**
 * 房源价格
 * 
 * @Author: Focci
 * @Date: 2023-12-13 21:52:02
 * @Last Modified by: Focci
 * @Last Modified time: 2023-12-13 21:52:02
 */

import { propertyType } from '@lib/config'
import { useTranslations } from 'next-intl'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
// import Number from '@comp/number'

export default function PropertyPriceLabel({ 
  Tag = 'div',
  typeId,
  type,
  priceLabel,
  priceDegree,
  ...props 
}) {
  const th = useTranslations('house')
  const tm = useTranslations('menu')

  const price = useMemo(() => {
    // 最近售出
    if(typeId === propertyType.SOLD) {
      // return (
      //   <Number>{`${th('soldPrice')}: ${priceLabel}`}</Number>
      // )
      return `${th('soldPrice')}: ${priceLabel}`
    }

    // 估价
    if(typeId === propertyType.ESTIMATE) {
      // 政府CV
      if(type === 'rv') {
        return `${th('govcv')}: ${priceLabel}`
      }
      // 后花园估价
      if(type === 'avm') {
        return `${tm('estimate')}: ${priceLabel}`
      }
    }

    return priceLabel
  }, [priceLabel, th, tm, type, typeId])

  return (
    <Tag {...props}>
      {price}
      {priceDegree && <small className="ml-1 text-red-500">{`↓${priceDegree}`}</small>}
    </Tag>
  )
}

PropertyPriceLabel.propTypes = {
  Tag: PropTypes.string,
  typeId: PropTypes.number,
  type: PropTypes.string,
  priceLabel: PropTypes.string,
  priceDegree: PropTypes.string,
}
