/**
 * StatusMask
 * 
 * @Author: Focci
 * @Date: 2023-11-17 14:26:42
 * @Last Modified by: Focci
 * @Last Modified time: 2023-11-17 14:26:42
 */

import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useTranslations } from 'next-intl'

export default function StatusMask({
  className = 'text-base',
  label = ''
}) {
  return (
    <div className="absolute inset-0 z-10 bg-black/60 flex items-center justify-center pointer-events-none">
      <span className={clsx(['text-white flex p-2 font-medium border-y tracking-[.3em]', className])}>
        {label}
      </span>
    </div>
  )
}

StatusMask.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
}

export function PropertyStatusMaks({
  className,
  status
}) {
  const tp = useTranslations('profile')
  if(!status || status === 1) return null

  return (
    <StatusMask 
      className={className} 
      label={status === 2 ? tp('sold') : tp('offlined')} 
    />
  )
}
