/**
 * 单列房源卡片
 * 
 * @Author: Focci
 * @Date: 2024-04-10 12:22:50
 * @Last Modified by: Focci
 * @Last Modified time: 2024-04-10 12:22:50
 */

'use client'

import PropTypes from 'prop-types'
import Link from 'next/link'
import { useMemo } from 'react'
import { map } from 'lodash-es'
import { listingCardClick } from './lib'
import PropertyCardScoff from './PropertyCardScoff'
import PropertyCardCover from './cover'
import CardAgent, { CardAgentAvatars } from './CardAgent'
import CardBrief, { CardBriefSimple } from './CardBrief'

function getPropertyCardHeader({ url, house, isPromote }) {
  return function PropertyCardBody({ inView }) {
    return isPromote
      ? (
        <CardAgent inView={inView} url={url} house={house} isPromote={false} />
      )
      : null
  }
}

function getPropertyCardBody({ url, house, isHouse, isPromote, agents, variant }) {
  return function PropertyCardBody({ inView }) {
    const { officeColor, officeFontColor } = house
    
    if(isHouse) {
      if(isPromote) {
        return (
          <div className="flex flex-col md:flex-row">
            <Link
              href={url}
              style={{ backgroundColor: officeColor, color: officeFontColor }}
              className="px-4 py-2 flex items-center justify-center min-w-[13rem]"
              {...listingCardClick(house)}
            >
              <div className="flex flex-row md:flex-col gap-2 items-center">
                <div className="flex items-center h-8 md:h-[5.5rem]">
                  {inView && (
                    <CardAgentAvatars
                      agents={agents} 
                      size={110}
                      hideAgentName
                      className="w-8 md:w-[5.5rem]"
                    />
                  )}
                </div>
                <span className="text-center text-f.8 font-medium leading-tight">
                  {map(agents, 'name').join(' & ')}
                </span>
              </div>
            </Link>
            <CardBrief url={url} house={house} variant="multiple" />
          </div>
        )
      }

      return (
        <>
          <CardAgent inView={inView} url={url} house={house} isPromote={false} />
          <CardBrief url={url} house={house} variant="multiple" />
        </>
      )
    }

    return (
      <CardBriefSimple url={url} house={house} variant={variant} />
    )
  }
}

export default function PropertyCardSingle({
  coverProps,
  cardWrapperClassName
}) {
  const PropertyCardHeader = useMemo(
    () => getPropertyCardHeader(coverProps), 
    [coverProps]
  )
  
  const PropertyCardBody = useMemo(
    () => getPropertyCardBody(coverProps), 
    [coverProps]
  )

  return (
    <PropertyCardScoff
      header={PropertyCardHeader}
      Cover={PropertyCardCover}
      CoverProps={coverProps}
      className={cardWrapperClassName}
    >
      {PropertyCardBody}
    </PropertyCardScoff>
  )
}

PropertyCardSingle.propTypes = {
  coverProps: PropTypes.object,
  cardWrapperClassName: PropTypes.string,
}
