import { isFunction } from 'lodash-es'

/**
 * 房源卡片点击
 */
export function listingCardClick(house) {
  const clickPropName = 'onListingCardClick'
  const fn = house?.customProps?.[clickPropName]
  
  return {
    onClick: (e) => {
      e.stopPropagation()
      isFunction(fn) && fn(house)
    }
  }
}
