/**
 * PropertyCardDefault
 * 
 * @Author: Focci
 * @Date: 2024-04-10 12:22:50
 * @Last Modified by: Focci
 * @Last Modified time: 2024-04-10 12:22:50
 */

'use client'

import PropTypes from 'prop-types'
import { useMemo } from 'react'
import PropertyCardScoff from './PropertyCardScoff'
import PropertyCardCover from './cover'
import CardAgent from './CardAgent'
import CardBrief, { CardBriefSimple } from './CardBrief'

function getPropertyCardBody({ 
  url, house, isHouse, variant, isPromote, agents, agentAvatarProps 
}) {
  return function PropertyCardBody({ inView }) {
    return !isHouse 
      ? <CardBriefSimple url={url} house={house} variant={variant} />
      : (
        <>
          <CardAgent 
            inView={inView} 
            url={url} 
            house={house} 
            isPromote={isPromote} 
            agents={agents} 
            agentAvatarProps={agentAvatarProps} 
          />
          <CardBrief 
            url={url} 
            house={house} 
            variant={variant} 
          />
        </>
      )
  }
}

export default function PropertyCardDefault({
  coverProps,
  cardWrapperClassName
}) {
  const PropertyCardBody = useMemo(
    () => getPropertyCardBody(coverProps), 
    [coverProps]
  )

  return (
    <PropertyCardScoff
      Cover={PropertyCardCover}
      CoverProps={coverProps}
      className={cardWrapperClassName}
    >
      {PropertyCardBody}
    </PropertyCardScoff>
  )
}

PropertyCardDefault.propTypes = {
  coverProps: PropTypes.object,
  cardWrapperClassName: PropTypes.string,
}
