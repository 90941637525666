/**
 * PropertyCardScoff
 * 
 * @Author: Focci
 * @Date: 2023-09-04 10:22:51
 * @Last Modified by: Focci
 * @Last Modified time: 2023-09-04 10:22:51
 */

'use client'

import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { Waypoint } from 'react-waypoint'
import { isFunction } from 'lodash-es'

export default function PropertyCardScoff({
  header,
  Cover, // 封面组件
  CoverProps = {}, // 封面组件参数
  className = 'rounded-md bg-white overflow-hidden h-full box-border shadow-listing',
  children
}) {
  const [inView, setInView] = useState(false)
  const handleEnter = useCallback(() => setInView(true), [])

  return (
    <Waypoint onEnter={handleEnter} topOffset={100} bottomOffset={380}>
      <div className={className}>
        {isFunction(header) ? header({ inView }) : header}
        {Cover && <Cover {...CoverProps} inView={inView} />}
        {isFunction(children) ? children({ inView }) : children}
      </div>
    </Waypoint>
  )
}

PropertyCardScoff.propTypes = {
  header: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  Cover: PropTypes.func,
  CoverProps: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
}
