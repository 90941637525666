import { pick, assign, isArray, map } from 'lodash-es'
import { houseSearchType, propertyType } from '@lib/config'
import publisher from '@img/publisher.png'

export const soldDefaultPrice = 'Sold price unknown'

export function getListingData(house, type) {
  const tmp = {
    __islist__: true,
    propertyIcon: pick(house, ['carspaces', 'bedrooms', 'bathrooms', 'floorarea', 'landarea']),
    typeId: house.type?.id,

    /**
     * customProps 用于传递用户自定义的一些参数
     * 1）{ onListingCardClick } 点击 Link onClick 事件
     */
    ...pick(house, ['code', 'office_id', 'simple_agents', 'office_external_id', 'external_id', 'customProps'])
  }
  
  tmp.tags = isArray(house.show_tags) ? map(house.show_tags, 'label') : []

  // 查成交
  if (type === houseSearchType.sold) {
    assign(tmp, pick(house, ['images', 'id', 'street']))
    tmp.propertyId = house.property_id
    tmp.title = house.title
    tmp.address = house.display_address || house.street
    tmp.priceLabel = house.sold_price_label || soldDefaultPrice
    tmp.soldData = /\d+/.test(house.sold_date) ? parseFloat(house.sold_date) : null
    tmp.sold = true
    tmp.slug = house.slug

    // 估价
  } else if (type === houseSearchType.estimate || type === houseSearchType.development) {
    assign(tmp, pick(house, ['images', 'id', 'lng', 'lat', 'slug', 'type']))
    tmp.title = house.unitary
    // 售出时间
    tmp.soldData = /\d+/.test(house?.latestSoldInfo?.transactionTime)
      ? parseFloat(house.latestSoldInfo.transactionTime)
      : null

    tmp.nolisting = true
    tmp.propertyId = house.id
    tmp.street = house?.location?.street || house.address
    tmp.address = house.displayAddress || house.address
    tmp.typeId = propertyType.ESTIMATE
    tmp.priceLabel = house[house.type]
    tmp.propertyIcon = {
      ...tmp.propertyIcon,
      carspaces: house.carSpaces,
      floorarea: house.floorArea,
      landarea: house.landArea,
    }

    // 默认 买房、租房
  } else {
    assign(tmp, pick(house, ['images', 'id', 'hideFavorite', 'animation', 'status']))
    tmp.title = house.teaser
    tmp.street = house.street || house.street_address
    tmp.address = house.display_address || (
      isArray(house.address)
        ? house.address.slice(0, 2).join(', ')
        : (house.street || house.street_address)
    )

    tmp.vrIcon = !!(house.threeDView || house.three_d_view)
    // tmp.typeId = house.type?.id
    tmp.agentName = house.agent_name
    tmp.simpleAgents = house.simple_agents
    tmp.isVideo = house.is_video
    tmp.videoImage = '' // 视频cover, 如果没有就留空
    tmp.floorPlanImage = '' // 户型图 cover, 如果没有就留空
    tmp.agentAvatar = house.agent_oss_avatar || ''
    tmp.officeName = house.office_name
    tmp.officeIcon = house.office_oss_avatar
    tmp.officeColor = house.office_color
    tmp.officeFontColor = house.font_color
    tmp.listAtLabel = house.list_at_label
    tmp.listAtLabelColor = house.list_at_label_color
    tmp.newListing = house.new_listing
    tmp.newHomes = house.new_homes
    // tmp.onSale = house.status === 1 // 在售
    tmp.openHomeLabel = house.open_home_label
    // tmp.showCase = house.is_showcase
    tmp.isFeature = house.is_feature
    tmp.slug = house.slug
    tmp.priceLabel = `${house.price_bold || ''} ${house.price_light || ''}`
    tmp.homePlans = isArray(house.home_plans) ? house.home_plans : []

    tmp.districtName = house.district_name
    tmp.regionName = house.region_name
    tmp.suburbName = house.suburb_name
    tmp.suburbId = house.suburb_id || house.suburbId
    tmp.priceDegree = house.price_degree // 降价信息

    tmp.isPublisher = house?.rental?.identity === 1 && !!house?.publisher

    // 如果是房东发布的房子
    if (tmp.isPublisher) {
      tmp.isFeature = true
      tmp.agentAvatar = house.publisher.avatar
      tmp.agentName = house.publisher.nickname
      tmp.officeIcon = publisher.src
      tmp.officeName = 'Publisher'
    }
  }

  return tmp
}
