/**
 * CoverScoff
 * 
 * @Author: Focci
 * @Date: 2023-09-02 10:52:17
 * @Last Modified by: Focci
 * @Last Modified time: 2023-09-02 10:52:17
 */

import PropTypes from 'prop-types'

export default function CoverScoff({
  variant,
  children,
  secondChildren,
  thirdChildren,
  aspectRatio = '4/3'
}) {
  if(variant === 'normal') {
    return (
      <div className="bg-cf0 rounded-t-sm relative" style={{ aspectRatio }}>
        {children}
      </div>
    )
  }
  return (
    <div className="flex gap-1">
      <div className="w-2/3">
        <div className="bg-cf0 rounded-t-sm relative h-full">
          {children}
        </div>
      </div>
      <div className="flex flex-col gap-1 w-1/3">
        <div className="bg-cf0 rounded-t-sm relative" style={{ aspectRatio }}>
          {secondChildren}
        </div>
        <div className="bg-cf0 rounded-t-sm relative" style={{ aspectRatio }}>
          {thirdChildren}
        </div>
      </div>
    </div>
  )
}

CoverScoff.propTypes = {
  variant: PropTypes.string,
}
